<template>
  <div id="cat">
    <b-row>
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showCategories"
        >
          <b-card>
            <b-card-header class="head">
              <b-card-title> {{ $t("Categories") }}</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-form @submit.prevent>
                <b-row>
                  <b-col md="6">
                    <b-row>
                      <b-col md="5">
                        <h6 class="text-primary font-weight-bold mb-2">
                          {{ $t("code.allC") }}
                          <feather-icon
                            icon="PlusCircleIcon"
                            size="20"
                            class="text-success cursor-pointer"
                            @click="AddCat()"
                          />
                        </h6>
                      </b-col>
                      <b-col md="4">
                        <b-form-input
                          v-model="searchCategories"
                          :placeholder="$t('subscriptions.minCharact')"
                          minlength="3"
                          @keyup="searchCategorieByName"
                        />
                      </b-col>
                      <b-col>
                        <b-button
                          variant="primary"
                          @click="searchCategorieByName(null, true)"
                        >
                          <feather-icon
                            icon="SearchIcon"
                            size="16"
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                    <draggable
                      ref="catlist"
                      :list="resCategories"
                      tag="ul"
                      :handle="resCategories.length == 0 ? '.handle' : false"
                      group="categorias"
                      class="scroll-list list-group list-group-flush cursor-move"
                    >
                      <b-list-group-item
                        v-for="c in resCategories"
                        :key="c.node.id"
                        tag="li"
                        @dragend="updateResCategories"
                      >
                        <div class="d-flex">
                          <div class="ml-25">
                            <b-card-text class="mb-0 font-weight-bold">
                              {{ c.node.name }}
                            </b-card-text>
                          </div>
                        </div>
                      </b-list-group-item>
                      <div
                        v-if="resCategories.length == 0"
                        class="mt-2"
                      >
                        {{ emptySearchCat }}
                      </div>
                    </draggable>
                  </b-col>
                  <b-col
                    md="6"
                    class="mt-sm-2 mt-md-0"
                  >
                    <h6 class="text-primary font-weight-bold mb-2">
                      {{ $t("subscriptions.selectedCategory") }}
                      <feather-icon
                        icon="XCircleIcon"
                        size="20"
                        class="text-danger cursor-pointer"
                        @click="QuitarCat($event)"
                      />
                    </h6>

                    <draggable
                      ref="catsel"
                      :list="categorySelected"
                      tag="ul"
                      group="categorias"
                      class="scroll-list list-group list-group-flush cursor-move"
                    >
                      <b-list-group-item
                        v-for="value in categorySelected"
                        :key="value.node.id"
                        tag="li"
                        @dragend="updateCategorySelected"
                      >
                        <div class="d-flex">
                          <div class="ml-25">
                            <b-card-text class="mb-0 font-weight-bold">
                              {{ value.node.name }}
                            </b-card-text>
                          </div>
                        </div>
                      </b-list-group-item>
                    </draggable>
                  </b-col>
                </b-row>
                <b-button
                  class="m-1"
                  variant="success"
                  @click="updateCategories"
                >
                  {{ $t("dataGeneric.save") }}
                </b-button>
              </b-form>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import {
  BButton, BRow, BCol, BCard, BOverlay, BForm, BCardBody, BCardTitle, BCardHeader, BCardText, BListGroupItem, BFormInput,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BButton,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BCardText,
    BListGroupItem,
    BOverlay,
    BForm,
    draggable,
  },
  props: {
    info: {
      default: [],
    },
  },
  data() {
    return {
      showContents: false,
      showCategories: false,
      contentSelected: [],
      allConts: [],
      categorySelected: [],
      allCats: [],
      resCategories: [],
      resContents: [],
      searchCategories: '',
      searchContents: '',
      emptySearchConts: this.$t('introduceNameCont'),
      emptySearchCat: this.$t('introduceNameCat'),
      userData: getUserData(),
    }
  },
  watch: {
    info() {
      this.categorySelected = this.info
      if (this.categorySelected.length === 0) this.categorySelected = []
    },

  },

  methods: {
    updateResCategories() {
      this.categorySelected.forEach(element => {
        this.allCats = this.allCats.filter(e => element.node.id !== e.node.id)
      })
    },
    updateCategorySelected() {
      const difference = this.resCategories.filter(
        x => !this.allCats.includes(x),
      )

      this.allCats = [...difference, ...this.allCats]
    },

    getCategories() {
      this.showCategories = true

      axios
        .post('', {
          query: `
             query{
              categoriesUnlimited(client:"${this.userData.profile.client.id}",name:"${this.searchCategories}"){
                  edges {
                      node {
                      id
                      name                    
                      }
                  }
              }                
          }   
          `,
        })
        .then(response => {
          messageError(response, this)

          const arr2 = response.data.data.categoriesUnlimited.edges
          const array = []
          for (let i = 0; i < arr2.length; i += 1) {
            let bool = false
            for (let j = 0; j < this.categorySelected.length; j += 1) {
              if (this.categorySelected[j].node.id === arr2[i].node.id) {
                bool = true
              }
            }
            if (!bool) {
              array.push(arr2[i])
            }
          }
          this.categories = arr2.slice()
          this.resCategories = array.slice()
          this.allCats = array.slice()
          this.emptySearchCat = this.$t('resultNotFound')
          this.showCategories = false
        })
    },
    getIdArray(array) {
      const res = []
      for (let i = 0; i < array.length; i += 1) {
        res.push(array[i].node.id)
      }
      return res
    },
    updateCategories() {
      const { id } = this.$route.params
      this.showCategories = true
      axios
        .post('', {
          query: `
               mutation{
                updateSubscription(id:"${id}",input:{
                    categorySuscriptions: [],              
                 }){
                    subscription
                   {                                 
                     name
                   }
                 }
               }
             `,
        })
        .then(result => {
          messageError(result, this)

          this.updateArrayData(this.getIdArray(this.categorySelected))
          showToast(this.$t('code.updateData'), 1, this)
          this.showCategories = false
        })
        .catch(err => {
          console.log(err)
          showToast(this.$t('code.updateDataError'), 0, this)
          this.showCategories = false
        })
    },
    updateArrayData(array) {
      const { id } = this.$route.params
      axios
        .post('', {
          query: `
                    mutation{
                        updateSubscription(id:"${id}",input:{
                            categorySuscriptions:[${array}]
                        }){
                            subscription
                                {
                                name
                                categorySuscriptions{
                                    edges{
                                    node{
                                        id
                                        name
                                    }
                                    }
                                }                        
                            }
                        }
                    }
                `,
        })
        .then(result => {
          messageError(result, this)
        })
        .catch(err => {
          console.log(err)
        })
    },

    QuitarCat(event) {
      if (this.categorySelected.length !== 0) {
        this.allCats = [...this.categorySelected, ...this.allCats]

        this.categorySelected = []

        this.searchCategorieByName(event, true)
      }
    },
    AddCat() {
      if (this.resCategories.length !== 0) {
        this.categorySelected = [...this.categorySelected, ...this.resCategories]

        this.resCategories.forEach(element => {
          this.allCats = this.allCats.filter(e => element.node.id !== e.node.id)
        })

        this.resCategories = []
      }
    },

    searchCategorieByName(event = null, pass = false) {
      let keycode = 0
      if (event) keycode = event.keyCode || event.which

      if (keycode === 13 || pass) {
        if (this.searchCategories.trim().length >= 3) {
          this.getCategories()
        } else {
          this.resCategories = []
          this.emptySearchCat = this.$t('introduceNameCat')
        }
      }
    },

  },
}
</script>
<style  lang="scss" scoped>
#cat .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
  // border: 1px solid;
  margin: 2px;
}

#cat .head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
